<template>
  <Button
    @click="
      $Notice.info({
        title: $t('COPIED_IN_CLIPBOARD'),
        duration: 1.5
      })
    "
    v-clipboard="() => value"
    type="text"
    size="small"
  >
    <fa icon="copy" />
  </Button>
</template>

<script>
export default {
  props: { value: { type: String, required: true } }
};
</script>

<style scoped></style>
