<template>
  <Loader :loading="$apollo.queries.user.loading">
    <Error :error="error">
      <Card dis-hover class="mt-5">
        <template #title>
          <fa :icon="['fa', 'user']" />
          <span class="ml-3">{{ user.name }}</span>
        </template>

        <p class="lowercase small-caps">{{ $t("EMAIL") }}:</p>
        <a
          :href="`mailto:${user.email}`"
          class="text-base text-blue-500 leading-none"
        >
          {{ user.email }}
        </a>
        <p class="lowercase small-caps">{{ $t("ID") }}:</p>
        {{ user.id }}
        <CopyToClipboardButton :value="user.id" class="align-baseline" />
        <p class="lowercase small-caps">{{ $t("CURRENT_ORGANIZATION") }}:</p>
        {{ userTenant.displayName || userTenant.name }}
      </Card>
      <div class="mt-5">
        <div>
          Where do you want this person to go faster than light?
        </div>
        <Select
          v-model="tenantId"
          filterable
          :disabled="switching"
          class="w-64"
        >
          <Option
            v-for="{ id, name, displayName } in switchableTenants"
            :value="id"
            :key="id"
          >
            {{ displayName || name }}
          </Option>
        </Select>
        <Button
          @click="switchTenant"
          :disabled="!tenantId || tenantId === userTenant.id"
          :loading="switching"
          class="mx-2"
        >
          <fa :icon="['far', 'transporter-2']" class="mr-1"></fa>
          Teleport him/her!
        </Button>
      </div>
    </Error>
  </Loader>
</template>

<script>
import CopyToClipboardButton from "@/components/CopyToClipboardButton.vue";
import switchTenantQuery from "./queries/switchTenant.gql";
import userQuery from "./queries/user.gql";
import Loader from "@/components/Loader/Loader.vue";
import Error from "@/components/Error/Error.vue";

export default {
  components: { Loader, Error, CopyToClipboardButton },
  data: () => ({
    error: null,
    tenantId: "",
    switching: false,
    user: {}
  }),
  computed: {
    userTenant: ({ user }) =>
      user?.roles?.find(
        ({ name, tenant }) => tenant.name !== "delight" && name !== "switcher"
      )?.tenant ?? {},
    switchableTenants: ({ user, userTenant }) =>
      user.switchableTenants.filter(({ id }) => id != userTenant.id)
  },
  apollo: {
    user: {
      query: userQuery,
      variables() {
        return { id: this.$route.params.userId };
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(graphQLErrors || networkError);
      }
    }
  },
  methods: {
    switchTenant() {
      this.switching = true;

      this.$apollo
        .mutate({
          mutation: switchTenantQuery,
          variables: {
            id: this.$route.params.userId,
            tenantId: this.tenantId
          }
        })
        .then(() =>
          this.$Notice.info({
            title: "to infinity and beyond!",
            duration: 1.5
          })
        )
        .catch(
          ({ graphQLErrors, networkError }) =>
            (this.error = JSON.stringify(networkError || graphQLErrors))
        )
        .finally(() => (this.switching = false));
    }
  }
};
</script>

<style scoped></style>
